<template>
    <navbar>
        <div class="navbar-brand" @click="$router.push('/')">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.6666 9.66667V12.3333H5.66663L13 19.6667L11.1066 21.56L0.546631 11L11.1066 0.440002L13 2.33334L5.66663 9.66667H21.6666Z"
            fill="#F2F2F2"
          />
        </svg>
      </div>
      <h6 class="title-navbar">
        Register Lembaga
      </h6>
    </navbar>
    <div class="container" v-if="!thankYouPage">
        <div class="register__lembaga-wrapper">
            <form class="needs-validation form__register-lembaga" novalidate @submit.prevent="handleSubmitRegisterCompany">
                <div class="input__group mb-3">
                    <label for="namaLembaga" class="label__form">Nama Lembaga</label>
                    <input
                    type="text"
                    class="form-control form__input"
                    placeholder="Masukkan Nama Lembaga"
                    aria-label="Masukkan Nama Lembaga"
                    aria-describedby="basic-addon1"
                    v-model="formRegisterCompany.nameCompany"
                    />
                </div>
                <div class="input__group mb-3">
                    <label for="namaPimpinan" class="label__form">Nama Pimpinan</label>
                    <input
                    type="text"
                    class="form-control form__input"
                    placeholder="Masukkan Nama Pimpinan"
                    aria-label="Masukkan Nama Pimpinan"
                    aria-describedby="basic-addon1"
                    v-model="formRegisterCompany.leaderName"
                    />
                </div>
                <div class="input__group mb-3">
                    <label for="picFundraising" class="label__form">PIC Fundraising</label>
                    <input
                    type="text"
                    class="form-control form__input"
                    placeholder="Masukkan PIC Fundraising"
                    aria-label="Masukkan PIC Fundraising"
                    aria-describedby="basic-addon1"
                    v-model="formRegisterCompany.picFundraising"
                    />
                </div>
                <div class="input__group mb-3">
                    <label for="alamat" class="label__form">Alamat</label>
                    <textarea class="form-control form__textarea" id="exampleFormControlTextarea1" rows="3" placeholder="Masukkan Alamat"
                    v-model="formRegisterCompany.address"></textarea>
                </div>
                <div class="input__group mb-3">
                    <label for="legalitas" class="label__form">Legalitas</label>
                    <input class="form-control form__input" type="file" id="formFile" placeholder="Upload Legalitas" accept="image/jpg, application/pdf" @change="handleUploadLegalitas">
                </div>
                <div class="input__group mb-3">
                    <label for="ponselPimpimnan" class="label__form">Nomor Ponsel Pimpinan</label>
                    <input type="number" class="form-controler form__input input-number" placeholder="Masukkan Nomor Ponsel Pimpinan" v-model="formRegisterCompany.phoneNumberLeader">
                </div>
                <div class="input__group mb-3">
                    <label for="ponselPIC" class="label__form">Nomor Ponsel PIC Fundraising</label>
                    <input type="number" class="form-controler form__input input-number" placeholder="Masukkan Nomor Ponsel PIC Fundraising" v-model="formRegisterCompany.phoneNumberFundraising">
                </div>
                <div class="input__group mb-3">
                    <label for="logo" class="label__form">Logo</label>
                    <input class="form-control form__input" type="file" id="formFile" placeholder="Upload Logo" accept="image/*" @change="handleUploadLogo">
                </div>
                <div class="input__group mb-3">
                    <label for="npwp" class="label__form">NPWP</label>
                    <input type="number" class="form-controler form__input input-number" placeholder="Masukkan Nomor NPWP" v-model="formRegisterCompany.npwp">
                </div>
                <div class="input__group mb-3">
                    <label for="ktp" class="label__form">KTP</label>
                    <input type="number" class="form-controler form__input input-number" placeholder="Masukkan Nomor KTP" v-model="formRegisterCompany.ktp">
                </div>
                <div class="input__group mb-3">
                    <label for="numberAccount" class="label__form">Nomor Rekening</label>
                    <input type="number" class="form-controler form__input input-number" placeholder="Masukkan Nomor Rekening" v-model="formRegisterCompany.accountNumber">
                </div>
                <div class="input__group mb-3">
                    <label for="nameBank" class="label__form">Nama Bank</label>
                    <input
                    type="text"
                    class="form-control form__input"
                    placeholder="Masukkan Nama Bank"
                    aria-label="Masukkan Nama Bank"
                    aria-describedby="basic-addon1"
                    v-model="formRegisterCompany.nameBank"
                    />
                </div>
                <div class="form_login-wrapper">
                    <label for="label_form" class="label_form-login">Data Login</label>
                    <div class="form_login-group">
                        <div class="input__group mb-3">
                            <label for="email" class="label__form">Email</label>
                            <input
                            type="text"
                            class="form-control form__input"
                            placeholder="Masukkan Email"
                            aria-label="Masukkan Email"
                            aria-describedby="basic-addon1"
                            v-model="formRegisterCompany.email"
                            @keyup="handleKeyupEmail"
                            />
                            <div class="invalid-feedback d-block">
                            {{ messageError.email }}
                            </div>
                        </div>
                        <div class="input__group mb-3">
                            <label for="phoneNumber" class="label__form">Nomor Ponsel</label>
                            <input
                            type="number"
                            class="form-control form__input input-number"
                            placeholder="Masukkan Nomor Ponsel"
                            aria-label="Masukkan Nomor Ponsel"
                            v-model="formRegisterCompany.phoneNumber"
                            />
                        </div>
                        <div class="input__group mb-3">
                            <label for="password" class="label__form">Password</label>
                            <input
                            type="password"
                            class="form-control form__input"
                            placeholder="Masukkan Password"
                            v-model="formRegisterCompany.password"
                            @keyup="handlePassword"
                            />
                            <div class="invalid-feedback d-block">
                            {{ messageError.password }}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="form-group signin-group">
                    <button
                    type="submit"
                    class="btn btn-sm btn-warning login-button w-100"
                    :disabled="disableBtnRegister || loading"
                    >
                    Daftar
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="container container__thank-you" v-else>
        <div class="content__thank-you">
            Terimakasih telah mendaftar, data lembaga anda akan diverifikasi tim admin. Info berikutnya akan kami kirimkan melalui email. <router-link to="/login" class="link__login">Klik link berikut untuk login ke amazingsedekah.com</router-link>
            <svg enable-background="new 0 0 24 24" height="100" viewBox="0 0 24 24" width="100" xmlns="http://www.w3.org/2000/svg"><path d="m11.84 7.63c-.52.26-1.16.26-1.68 0l-10.16-5.03v9.4c0 1.24 1.01 2.25 2.25 2.25h7.513 11.487v-11.65z" fill="#eceff1"/><path d="m21.25 3.5v.6l-9.41 5.03c-.52.26-1.16.26-1.68 0l-10.16-5.03v-.6c0-1.24 1.01-2.25 2.25-2.25h16.75c1.24 0 2.25 1.01 2.25 2.25z" fill="#b0bec5"/><circle cx="17.5" cy="17.5" fill="#ffc107" r="6.5"/><path d="m20.25 18.25h-2.75c-.414 0-.75-.336-.75-.75v-2.75c0-.414.336-.75.75-.75s.75.336.75.75v2h2c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11.5c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"/><path d="m9.25 15h-7c-1.24 0-2.25-1.009-2.25-2.25v-9.5c0-1.241 1.01-2.25 2.25-2.25h17.5c1.24 0 2.25 1.009 2.25 2.25v6c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-6c0-.414-.337-.75-.75-.75h-17.5c-.413 0-.75.336-.75.75v9.5c0 .414.337.75.75.75h7c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m11.001 9.827c-.404 0-.809-.092-1.177-.277l-9.407-4.658c-.371-.184-.522-.633-.339-1.005.184-.371.631-.524 1.005-.339l9.41 4.66c.311.155.706.155 1.011.001l9.413-4.661c.374-.185.821-.032 1.005.339.184.372.032.821-.339 1.005l-9.41 4.66c-.366.183-.769.275-1.172.275z"/></svg>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert2'
import Navbar from '../components/TransactionComponent/NavbarTransaction.vue'
export default {
    components: { Navbar },
    data() {
        return {
            formRegisterCompany: {
                nameCompany: '',
                leaderName: '',
                picFundraising: '',
                address: '',
                legalitas: '',
                phoneNumberLeader: '',
                phoneNumberFundraising: '',
                logo: '',
                npwp: '',
                ktp: '',
                accountNumber: '',
                nameBank: '',
                email: '',
                phoneNumber: '',
                password: ''
            },
            messageError: {
                email : '',
                password: ''
            },
            thankYouPage: false,
            loading: false,
        }
    },
    computed: {
        disableBtnRegister() {
            if (this.formRegisterCompany.nameCompany === '' || this.formRegisterCompany.leaderName === ''
            || this.formRegisterCompany.picFundraising === '' || this.formRegisterCompany.address === ''
            || this.formRegisterCompany.legalitas === '' || this.formRegisterCompany.phoneNumberLeader === ''
            || this.formRegisterCompany.phoneNumberFundraising === '' || this.formRegisterCompany.logo === ''
            || this.formRegisterCompany.npwp === '' || this.formRegisterCompany.ktp === '' || this.formRegisterCompany.accountNumber === ''
            || this.formRegisterCompany.nameBank === '' || this.formRegisterCompany.email === '' || this.formRegisterCompany.phoneNumber === '' || this.formRegisterCompany.password === ''
            || this.messageError.email !== '' || this.messageError.password !== '') {
                return true
            }

            return false
        }
    },
    methods: {
        handleUploadLegalitas(e) {
            const file = e.target.files[0];
            if (file.size > 10000000) {
                this.formRegisterCompany.legalitas = null
                swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'file terlalu besar, harus dibawah 10 mb'
                })
            }else{
                if (typeof FileReader === "function") {
                    this.formRegisterCompany.legalitas = file
                } else {
                    alert("Sorry, FileReader API not supported");
                }
            }
        },
        handleUploadLogo(e) {
            const file = e.target.files[0];
            if (file.size > 512000) {
                this.formRegisterCompany.logo = null
                swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'file terlalu besar, harus dibawah 500kb'
                })
            }else{
                if (!file.type.includes("image/")) {
                    swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'type file, harus beruapa image atau pdf'
                    })
                }
                if (typeof FileReader === "function") {
                    this.formRegisterCompany.logo = file
                } else {
                    alert("Sorry, FileReader API not supported");
                }
            }
        },
        handleKeyupEmail($event) {
            const formatEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            const form = document.querySelector('.needs-validation')

            if (this.formRegisterCompany.email === '') {
                this.messageError.email = 'Email tidak boleh kosong'
                $event.target.required = true
                form.classList.add('was-validated')
            } else if (!formatEmail.test($event.target.value)) {
                this.messageError.email = 'Format email tidak valid'
                $event.target.required = true
                form.classList.add('was-validated')
            } else {
                this.messageError.email = ''
                form.classList.remove('was-validated')
                $event.target.required = false
            }
        },
        handlePassword($event){
            const form = document.querySelector('.needs-validation')

            if (this.formRegisterCompany.password === '') {
                this.messageError.password = 'Password tidak boleh kosong'
                $event.target.required = true
                form.classList.add('was-validated')
            } else if ($event.target.value.length <= 7) {
                this.messageError.password = 'Password kurang dari 8 karakter'
                $event.target.required = true
                form.classList.add('was-validated')
            } else {
                this.messageError.password = ''
                form.classList.remove('was-validated')
                $event.target.required = false
            }
        },
        handleSubmitRegisterCompany() {
            const formData = new FormData()
            formData.append('company_name',this.formRegisterCompany.nameCompany)
            formData.append('company_leader',this.formRegisterCompany.leaderName)
            formData.append('company_leader_phone_number',this.formRegisterCompany.phoneNumberLeader)
            formData.append('company_address',this.formRegisterCompany.address)
            formData.append('company_legal_document',this.formRegisterCompany.legalitas)
            formData.append('company_contact_person_name',this.formRegisterCompany.picFundraising)
            formData.append('company_contact_person_phone_number',this.formRegisterCompany.phoneNumberFundraising)
            formData.append('company_logo',this.formRegisterCompany.logo)
            formData.append('company_npwp_number',this.formRegisterCompany.npwp)
            formData.append('company_id_card_number',this.formRegisterCompany.ktp)
            formData.append('company_bank_account_number',this.formRegisterCompany.accountNumber)
            formData.append('company_bank_account_name',this.formRegisterCompany.nameBank)
            formData.append('user_email',this.formRegisterCompany.email)
            formData.append('user_phone_number',this.formRegisterCompany.phoneNumber)
            formData.append('user_password',this.formRegisterCompany.password)
            formData.append('company_status','active')

            swal.fire({
                title: '', // add html attribute if you want or remove
                text: 'Mohon Menunggu',
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: function() {
                    swal.showLoading()
                }
            })
            this.loading = true

            this.$http.post(`${process.env.VUE_APP_API_URL}companies`,formData)
            .then(({data}) => {
                swal.close()
                swal.hideLoading()
                this.loading = false
                this.thankYouPage = true
            })
            .catch(error => {
                this.loading = false
                
                if (error.response.status === 500) {
                    swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Terjadi kesalahan'
                    })
                }

                if (error.response.status === 422) {
                    const validationMessages = error.response.data.errors
                    let errorMessages = '<ul>'
                    for (let i = 0; i < Object.keys(validationMessages).length; i++) {
                    errorMessages = errorMessages + '<li>' + validationMessages[Object.keys(validationMessages)[i]] + '</li>'
                    }
                    errorMessages = errorMessages + '</ul>'
                    
                    swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        html: errorMessages
                    })
                }
            })
        }
    }
}
</script>